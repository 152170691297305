<template>
    <div class="createCityOffice">
        <h1>创城办</h1>
        <el-form :inline="true">
            <el-form-item label="名称">
                <el-input v-model="searchParam.nameLike" style="width: 180px;" />
            </el-form-item>
            <el-form-item>
                <el-button-group>
                    <el-button type="primary" @click="commitFindList()">搜索</el-button>
                    <el-button type="info" @click="exportExcel()">（还没）导出</el-button>
                    <el-button @click="resetSearchParam()">重置</el-button>
                </el-button-group>
            </el-form-item>
            <el-form-item>
                <el-button-group>
                    <el-button type="primary" @click="data.editData = {}; show.editShow = true">新增</el-button>
                    <el-button type="info" @click="show.importShow = true">（还没）导入</el-button>
                    <el-button type="warning" :disabled="!(show.selected.length == 1)" @click="update()">编辑</el-button>
                    <el-button type="danger" :disabled="!(show.selected.length >= 1)" @click="del()">删除</el-button>
                </el-button-group>
            </el-form-item>
        </el-form>
        <el-table :data="data.list" @selection-change="selectionChange()" ref="tabRef" border style="height: calc(100vh - 310px);">
            <el-table-column type="selection" width="55" align="center" />
            <el-table-column prop="name" label="名称" width="200" show-overflow-tooltip>
                <template #default="scope">
                    <el-link type="primary" @click="update(scope.row)">{{ scope.row.name }}</el-link>
                </template>
            </el-table-column>
            <el-table-column prop="parentName" label="上级单位" width="150" show-overflow-tooltip >
                <template #default="scope">
                    <span v-if="scope.row.parentName">{{ scope.row.parentName }}</span>
                    <span v-else>-</span>
                </template>
            </el-table-column>
            <el-table-column prop="areaCode" label="区划编码" width="100" show-overflow-tooltip />
            <el-table-column prop="areaName" label="区划" width="200" show-overflow-tooltip />
            <el-table-column prop="address" label="详细地址" show-overflow-tooltip />
        </el-table>
        <div style="height: 10px;" />
        <el-pagination v-model:current-page="searchParam.pageNum" v-model:page-size="searchParam.pageSize" :page-sizes="[50, 100, 200]" background layout="total, sizes, prev, pager, next, jumper" :total="data.total" @change="commitFindList()" />
    </div>

    <el-dialog v-model="show.editShow" :title="data.editData.id ? '修改' : '新增'" width="500">
        <el-form>
            <el-form-item label="ID" v-show="false">
                <el-input v-model="data.editData.id" />
            </el-form-item>
            <el-form-item label="名称">
                <el-input v-model="data.editData.name" />
            </el-form-item>
            <el-form-item label="区划编码">
                    <el-select v-model="data.editData.areaCode" filterable remote reserve-keyword placeholder="输入名称搜索并选择" :remote-method="loadAreaCodeOptions" style="width: 100%">
                        <el-option v-for="item in show.areaCodeOptions" :key="item.areaCode" :label="item.fullName + '(' + item.areaCode + ')'" :value="item.areaCode" />
                    </el-select>
                </el-form-item>
            <el-form-item label="地址">
                <el-input v-model="data.editData.address" />
            </el-form-item>
            <el-form-item label="级别">
                <el-select v-model="data.editData.level" placeholder="请选择" style="width: 100%">
                    <el-option label="市" value="city" />
                    <el-option label="区、县" value="area" />
                    <el-option label="乡镇、街道" value="town" />
                </el-select>
            </el-form-item>
            <el-form-item label="上级">
                <el-select v-model="data.editData.parentId" filterable remote reserve-keyword placeholder="输入名称搜索并选择" :remote-method="loadCreateCityOfficeOptions" style="width: 100%">
                    <el-option v-for="item in show.createCityOfficeOptions" :key="item.id" :label="item.name" :value="item.id" />
                </el-select>
            </el-form-item>
        </el-form>
        <template #footer>
            <div class="dialog-footer">
                <el-button type="primary" @click="edit()">确认</el-button>
            </div>
        </template>
    </el-dialog>

    <el-dialog v-model="show.importShow" title="确认导入" width="500">
        <el-form>
            <el-form-item>
                <el-text>请先</el-text>
                <el-link type="primary" @click="importExcelTemplate()">下载导入模版</el-link>
                <el-text>，填写模板内容再导入。</el-text>
                <br>
                <el-text type="danger">模板格式不对应可能造成倒入数据混乱，请注意。</el-text>
            </el-form-item>
        </el-form>
        <template #footer>
            <div class="dialog-footer">
                <el-upload :action="api.baseURL() + '/backend/createCityOffice/importExcel'" :headers="api.tokenHeader()" :on-progress="importExcelProgress" :on-success="importExcelSuccess" :on-error="importExcelError" :show-file-list="false">
                    <el-button type="primary">导入</el-button>
                </el-upload>
            </div>
        </template>
    </el-dialog>
</template>

<script setup>
import { reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import { ElMessage, ElMessageBox } from 'element-plus'
import api from '../../apis/api'
import apiNative from '../../apis/apiNative'
import util from '../../utils/util'
import math from '../../utils/math'

const router = useRouter()
const tabRef = ref()

const data = reactive({
    total: 0,
    list: [],
    accumulate: {},
    editData: {},
})

const show = reactive({
    selected: [],
    importShow: false,
    createCityOfficeOptions: [],
    areaCodeOptions: [],
})

const loadAreaCodeOptions = (keyword) => {
    
    const params = {
        keyWord: keyword
    }
    api.get('/backend/ad/page', { params }).then(res => {
        show.areaCodeOptions = res.list
    })
}

const searchParam = reactive({})
const resetSearchParam = () => {
    searchParam.nameLike = null
    searchParam.pageNum = 1
    searchParam.pageSize = 50
}
resetSearchParam()

const loadCreateCityOfficeOptions = (nameLike) => {
    api.get('/backend/createCityOffice/page', { params: { 'nameLike': nameLike } }).then(res => {
        show.createCityOfficeOptions = res.list
    })
}

const getPageParam = () => {
    const params = {}
    params.pageNum = searchParam.pageNum
    params.pageSize = searchParam.pageSize
    params.nameLike = searchParam.nameLike
    return params
}

const commitFindList = () => {
    api.get('/backend/createCityOffice/page', { params: getPageParam() }).then(res => {
        data.list = res.list
        data.total = res.total
    })
}
commitFindList()

const selectionChange = () => {
    show.selected = tabRef.value.getSelectionRows()
}

const update = (row) => {
    const createCityOffice = row ? row : tabRef.value.getSelectionRows()[0]
    data.editData = JSON.parse(JSON.stringify(createCityOffice))
    loadAreaCodeOptions(data.editData.areaCode)
    if (data.editData.parentId) {
        show.createCityOfficeOptions = [{
            id: data.editData.parentId,
            name: data.editData.parentName
        }]
    }
    show.editShow = true
}

const edit = () => {
    if (data.editData.id) {
        submitUpdate()
    } else {
        submitAdd()
    }
}

const submitAdd = () => {
    api.post('/backend/createCityOffice/add', data.editData).then(() => {
        ElMessage.success('添加成功')
        show.editShow = false
        commitFindList()
    })
}

const submitUpdate = () => {
    api.post('/backend/createCityOffice/set', data.editData).then(() => {
        ElMessage.success('修改成功')
        show.editShow = false
        commitFindList()
    })
}

const del = () => {
    const ids = tabRef.value.getSelectionRows().map(createCityOffice => createCityOffice.id)
    ElMessageBox.confirm('', {
        title: '确认删除',
        message: '将要删除选中的 ' + ids.length + ' 项。',
        autofocus: false,
        confirmButtonText: '确认',
        cancelButtonText: '取消'
    }).then(() => {
        api.post('/backend/createCityOffice/del', { ids: ids }).then(() => {
            ElMessage.success('删除成功')
            commitFindList()
        })
    })
}


const importExcelTemplate = () => {
    apiNative.get('/backend/createCityOffice/importExcelTemplate', {
        responseType: 'blob'
    }).then(res => {
        ElMessage.success('开始下载')
        util.downloadFile(res, '销售明细-导入模版', 'xlsx')
    }).catch(() => {
        ElMessage.error('下载失败，请联系系统管理员')
    })
}

const importExcelProgress = () => {
    ElMessage.success('正在导入！如果数据量大可能时间较长，请耐心等待。')
}

const importExcelSuccess = (res) => {
    if (res.code != 'S000001') {
        ElMessage.error('导入失败，请联系系统管理员')
        return
    }
    show.importShow = false
    let message = '<p>本次导入，共计识别到：' + res.data.all + '条数据</p>'
    message += '<p>成功：<span style="color:green"> ' + res.data.success + ' </span>条数据</p>'
    message += '<p>失败：<span style="color:red"> ' + res.data.error + ' </span>条数据</p>'
    if (res.data.error > 0) {
        for (const failIndex in res.data.failRowList) {
            message += '<p>第 ' + res.data.failRowList[failIndex].row + ' 行：' + res.data.failRowList[failIndex].reason + '</p>'
        }
    }
    ElMessageBox.alert('', {
        title: '导入完成',
        message: message,
        autofocus: false,
        dangerouslyUseHTMLString: true,
        confirmButtonText: '确定'
    })
    res.data
}

const importExcelError = () => {
    ElMessage.error('导入失败，请联系系统管理员')
}

const exportExcel = () => {
    ElMessageBox.confirm('', {
        title: '确认导出',
        message: '将要按照当前选择的查询条件进行导出。',
        autofocus: false,
        confirmButtonText: '确认',
        cancelButtonText: '取消'
    }).then(() => {
        ElMessage.success('正在导出！如果数据量大可能时间较长，请耐心等待。')
        apiNative.get('/backend/createCityOffice/exportExcel', {
            params: getPageParam(),
            responseType: 'blob'
        }).then(res => {
            searchParam.exportShow = false
            ElMessage.success('开始下载')
            util.downloadFile(res, '销售明细' + util.parseTime(new Date(), '{y}{m}{d}{h}{i}{s}'), 'xlsx')
        }).catch(() => {
            ElMessage.error('导出失败，请联系系统管理员')
        })
    })
}
</script>

<style lang="less"></style>